import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { ImageService } from 'src/app/services/image.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-cash-in',
  templateUrl: './cash-in.component.html',
  styleUrls: ['./cash-in.component.scss']
})
export class CashInComponent implements OnInit {

  bucket?: string;

  id?: string;
  user?: any;
  cashIn?: any;

  sent = false;

  fromCashIns = false;
  fromAdminCashIns = false;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient, private modalService: NgbModal, private wsService: WsService, private userService: UserService, private authService: AuthService) { 
    this.id = route.snapshot.params['id'];
    this.bucket = ImageService.cashInBucket;
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { cashIns?: boolean, adminCashIns?: boolean };
    if (state && state.cashIns) {
      this.fromCashIns = state.cashIns;
    }
    if (state && state.adminCashIns) {
      this.fromAdminCashIns = state.adminCashIns;
    }
    
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    //this.googleMap!.setMyLocationEnabled(true);
    this.route.paramMap.subscribe(params => { 
      this.cashIn = null;
      this.id = params.get('id')!; 
      this.httpClient.get('/api/admin/cashins/' + this.id,  {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        this.cashIn = result.cashIn;
      })
    });
  }

  applyCashIn() {
      let body = { 
        receiver: this.cashIn.user, 
        amount: this.cashIn.amount, 
        currency: this.cashIn.currency,
        type: 'GCASH',
        reference: this.cashIn.reference,
        cashin: this.id
      };
      this.httpClient.post('/api/admin/cashin', body, {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        if (result.result === 0) {
          this.cashIn.complete = true;
        }
      });
  }

  back() {
    if (this.fromCashIns) {
      this.router.navigate(['/cash-ins']);
    } else if (this.fromAdminCashIns) {
      this.router.navigate(['/admin-cash-ins']);
    } else {
      this.router.navigate(['/']);
    }
  }

}
