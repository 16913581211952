import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { BookingsService } from 'src/app/services/bookings.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.sass']
})
export class BookingsComponent implements OnInit {

  bookings?: any;

  user?: any;

  constructor(private httpClient: HttpClient, private wsService: WsService, private router: Router, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) { 
    this.bookings = this.route.snapshot.data['bookings'];
    /*this.bookingsService.bookingsSubject.subscribe((bookings) => {
      this.bookings = bookings;
    });*/
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });

    this.wsService.observable.subscribe((message: any) => {
      //let message = JSON.parse(messageEvent.data);
      console.log("Received message " + JSON.stringify(message));
      if (this.user) {
        if (message.type == 'booking') {
          if (message.user === this.user.id) {
            this.updateBooking(message);
          } else if (message.senderAgent === this.user.id) {
            this.updateBooking(message);
          } else if (message.receiverAgent === this.user.id) {
            this.updateBooking(message);
          } else if (message.receiver === this.user.id) {
            this.updateBooking(message);
          }
        }
      }
    });

  }

  ngOnInit(): void {
    /*this.httpClient.get('/api/bookings', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result) => {
      this.bookings = result;
    })*/  
  }

  openBooking(id: string) {
    this.router.navigate(['/bookings/' + id],{ state:{ bookings: true }});
  }

  updateBooking(message: any) {
    console.log('Updating booking');
    //let bookings = this.bookingsSubject.getValue();

    let found = false;
    for (let existingBooking of this.bookings) {
      if (existingBooking.id == message.id) {
        existingBooking.amount = message.amount;
        existingBooking.balance = message.balance;
        existingBooking.user = message.user;
        existingBooking.receiver = message.receiver;
        existingBooking.senderAgent = message.senderAgent;
        existingBooking.receiverAgent = message.receiverAgent;
        existingBooking.cashPickedUp = message.cashPickedUp;
        existingBooking.cashDroppedOff = message.cashDroppedOff;
        found = true;
      }
    }

    if (!found) {
      let booking = {id: message.id, name: message.name, amount: message.amount, user: message.user, receiver: message.receiver, senderAgent: message.senderAgent, recieverAgent: message.receiverAgent, code: message.code };
      this.bookings.unshift(booking);
    }

    //this.bookingsSubject.next(bookings);
  }

  back() {
    this.router.navigate(['/']);
  }

}
