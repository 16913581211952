// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  wsUrl: 'wss://mjds7k4s8f.execute-api.ap-southeast-1.amazonaws.com/Prod/',
  kycBucket: 'https://fastsendapi-test-kycbucket-pa2zv9fiw24n.s3.ap-southeast-1.amazonaws.com/',
  photoBucket: 'https://fastsendapi-test-photobucket-di47v5lrzx4q.s3.ap-southeast-1.amazonaws.com/',
  cashInBucket: 'https://fastsendapi-test-cashinbucket-hop25g165xuz.s3.ap-southeast-1.amazonaws.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
