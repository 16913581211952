import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.scss']
})
export class AdminUserComponent implements OnInit {

  id?: string;
  user?: any;

  balances?: any[];
  cashOnHand?: any[];
  bookings?: any;
  payments?: any;
  kycs?: any;
  transactions?: any;

  transactionsBalance?: number;
  transactionsIncome?: number;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient, private modalService: NgbModal, private wsService: WsService, private userService: UserService, private authService: AuthService) { 
    this.id = route.snapshot.params['id'];
  }

  ngOnInit(): void {
    //this.googleMap!.setMyLocationEnabled(true);
    this.route.paramMap.subscribe(params => { 
      this.user = null;
      this.id = params.get('id')!; 
      this.httpClient.get('/api/admin/users/' + this.id,  {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        this.user = result;
        if (this.user.balances) {
          this.balances = Object.keys(this.user.balances).map(key => ({currency: key, amount: this.user.balances[key]}));
        }
        if (this.user.cashOnHand) {
          this.cashOnHand = Object.keys(this.user.cashOnHand).map(key => ({currency: key, amount: this.user.cashOnHand[key]}));
        }
        this.loadBookings();
        this.loadPayments();
        this.loadTransactions();
        this.loadKycs();
      })
    });
  }

  openKyc(kyc: any) {
    this.router.navigate(['/kyc/' + kyc.userId + '!' + kyc.id]);
  }

  loadBookings() {
    this.httpClient.get('/api/admin/users/' + this.id + '/bookings', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((results: any) => {
      this.bookings = results;
    });
  }

  openBooking(id: string) {
    this.router.navigate(['/bookings/' + id]);
  }

  loadPayments() {
    this.httpClient.get('/api/admin/users/' + this.id + '/payments', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((results: any) => {
      this.payments = results;
    });
  }

  openPayment(id: string) {
    this.router.navigate(['/payments/' + id]);
  }

  loadKycs() {
    this.httpClient.get('/api/admin/users/' + this.id + '/kycs', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((results: any) => {
      this.kycs = results;
    });
  }

  loadTransactions() {
    this.httpClient.get('/api/admin/users/' + this.id + '/transactions', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((results: any) => {
      this.transactions = results;
      let transactionsBalance = 0;
      let transactionsIncome = 0;
      for (let transaction of this.transactions) {
        transactionsBalance += transaction.amount;
        if (transaction.income != undefined) {
          transactionsIncome += transaction.income;
        }
      }
      this.transactionsBalance = transactionsBalance;
      this.transactionsIncome = transactionsIncome;
    });
  }

  back() {
    this.router.navigate(['/admin-users']);
  }

}
