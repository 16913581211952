<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<div class="d-flex justify-content-center align-items-center w-100 mt-2 mx-2">
  <div *ngIf="booking" style="width:100%; max-width: 700px">
    <h2>Booking</h2>
    <div>{{ booking.id }}</div>
    <div *ngIf="user">
      <span *ngIf="booking.user != booking.sender && booking.user == user.id" class="badge bg-success">Creator</span>
      <span *ngIf="booking.sender == user.id" class="badge bg-success">Sender</span>
      <span *ngIf="booking.senderAgent == user.id" class="badge bg-info">Sender Agent</span>
      <span *ngIf="booking.receiverAgent == user.id" class="badge bg-warning">Receiver Agent</span>
      <span *ngIf="booking.receiver == user.id" class="badge bg-danger">Receiver</span>
    </div>
    <div>Date: {{ booking.date | date:'long' }}</div>
    <div>Type: {{ booking.type }}</div>
    <div *ngIf="booking.name">Name: {{ booking.name }}</div>
    <div>Amount: {{ booking.amount | currency:booking.currency }}</div>
    <div>Sender Agent Fee: {{ booking.senderAgentFee | currency:booking.currency }}</div>
    <div>Receiver Agent Fee: {{ booking.receiverAgentFee | currency:booking.currency }}</div>
    <div>Platform Fee: {{ booking.platformFee | currency:booking.currency }}</div>
    <div>Tip: {{ booking.tip | currency:booking.currency }}</div>
    <div>Total: {{ booking.total | currency:booking.currency }}</div>
    <div *ngIf="booking.currency !== booking.receiverCurrency">Receiver Amount: {{ booking.receiverAmount | currency:booking.receiverCurrency }}</div>
    <div *ngIf="booking.balance !== undefined">Booking Balance: {{ booking.balance | currency:booking.currency }}</div>
    <div>Purpose: {{ booking.purpose }}</div>
    <div>Location: {{ booking.location }}</div>
    <div>Sender: <a [href]="'/admin-users/' + booking.sender">{{ booking.senderDetail.fullName }}</a></div>
    <div>Receiver: <a [href]="'/admin-users/' + booking.receiver">{{ booking.receiverDetail.fullName }}</a></div>
    <div *ngIf="booking.payment">Payment: <a [href]="'/payments/' + booking.payment">{{ booking.payment }}</a></div>
    <div *ngIf="booking.receiver === user.id && booking.code">
      Code: {{ booking.code }}
    </div>
    <div *ngIf="booking.senderAgentsOffered !== undefined">
      Sending Agents Offered: {{ booking.senderAgents.length }}
    </div>
    <div *ngIf="booking.receiverAgentsOffered !== undefined">
      Receiving Agents Offered: {{ booking.receiverAgentsOffered }}
    </div>
    <div *ngIf="booking.senderAgent">
      Sending Agent Assigned:
      <span *ngIf="booking.senderAgentDetail">{{ booking.senderAgentDetail.fullName }} </span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
        viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
    </div>
    <div *ngIf="booking.receiverAgent">
      Receiving Agent Assigned:
      <span *ngIf="booking.receiverAgentDetail">{{ booking.receiverAgentDetail.fullName }} </span>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
        viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
    </div>
    <div *ngIf="booking.cashPickedUp">Cash Picked Up
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
        viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
    </div>
    <div *ngIf="booking.cashDroppedOff">Cash Dropped Off
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
        viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
    </div>
    <div *ngIf="booking.complete">Complete
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
        viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
    </div>
    <div *ngIf="booking.cancelled">Cancelled
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
        viewBox="0 0 16 16">
        <path
          d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
      </svg>
    </div>
    <div *ngIf="booking.cancellationReasonCode != undefined">
      Cancellation Reason Code: {{ booking.cancellationReasonCode }}
    </div>
    <div *ngIf="booking.cancellationReasonMessage != undefined">
      Cancellation Reason Message: {{ booking.cancellationReasonMessage }}
    </div>
    <div *ngIf="booking.senderAgent == user.id && !booking.cashPickedUp">
      <button class="btn btn-primary" (click)="receiveCash()" [disabled]="user.balance < booking.amount">Receive
        Cash</button>
      <button class="btn btn-secondary" (click)="cancelPickup()" style="margin-left: 5px">Cancel</button>
    </div>
    <div *ngIf="booking.receiverAgent == user.id && !booking.cashDroppedOff">
      <button class="btn btn-primary" (click)="giveCash()" [disabled]="!booking.balance || booking.balance == 0">Give
        Cash</button>
      <button class="btn btn-secondary" (click)="cancelDeliver()" style="margin-left: 5px">Cancel</button>
    </div>
    <div *ngIf="apiLoaded">
      <google-map #map height="400px" width="100%" [center]="center">
        <map-marker *ngIf="senderMarkerPosition" [position]="senderMarkerPosition" [options]="senderMarkerOptions">
        </map-marker>
        <map-marker *ngIf="senderAgentMarkerPosition" [position]="senderAgentMarkerPosition"
          [options]="senderAgentMarkerOptions"></map-marker>
        <map-marker *ngIf="receiverAgentMarkerPosition" [position]="receiverAgentMarkerPosition"
          [options]="receiverAgentMarkerOptions"></map-marker>
        <map-marker *ngIf="receiverMarkerPosition" [position]="receiverMarkerPosition"
          [options]="receiverMarkerOptions"></map-marker>
        <map-marker *ngFor="let senderOutlet of booking.receiverAgents"
          [position]="{lat: senderOutlet.latitude, lng: senderOutlet.longitude }" [label]="senderOutlet.name"
          [options]="outletMarkerOptions"></map-marker>
      </google-map>
      <button class="btn btn-primary" (click)="cancelBooking()">Cancel Booking</button>
      <div *ngIf="booking.sender == user.id"><button class="btn btn-primary" (click)="openRoleRating('SENDER')">Rate Pickup Agent</button></div>
      <div *ngIf="booking.receiver == user.id"><button class="btn btn-primary" (click)="openRoleRating('RECEIVER')">Rate Dropoff Agent</button></div>
      <div *ngIf="booking.senderAgent == user.id"><button class="btn btn-primary" (click)="openRoleRating('PICKUPAGENT')">Rate Sender</button></div>
      <div *ngIf="booking.receiverAgent == user.id"><button class="btn btn-primary" (click)="openRoleRating('DROPOFFAGENT')">Rate Receiver</button></div>
      <button class="btn btn-primary" (click)="findAgents()">Find Agents</button>
      <div *ngFor="let message of messages">
        {{ message.message }}
      </div>
      <form [formGroup]="formGroup">
        <input type="text" formControlName="message" (keydown)="onKeyDown($event)">
      </form>
    </div>
  </div>
  <div *ngIf="!booking">
    Loading Booking
  </div>
</div>