import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-users',
  templateUrl: './admin-users.component.html',
  styleUrls: ['./admin-users.component.scss']
})
export class AdminUsersComponent implements OnInit {

  users?: any;

  name?: string;
  id?: string;

  user?: any;

  radio: UntypedFormControl = new UntypedFormControl('EMAIL');

  emailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('')
  });

  phoneForm: UntypedFormGroup = new UntypedFormGroup({
    phone: new UntypedFormControl('')
  });

  idForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl('')
  });

  searchForm: UntypedFormGroup = new UntypedFormGroup({
    fullName: new UntypedFormControl('')
  });


  constructor(private httpClient: HttpClient, private router: Router, private wsService: WsService, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) { 
    //this.users = this.route.snapshot.data['users'];
    /*this.bookingsService.bookingsSubject.subscribe((bookings) => {
      this.bookings = bookings;
    });*/
    /*this.userService.currentUserSubject.subscribe((user: any) => {
      this.user = user;
    });*/

    /*this.wsService.observable.subscribe((message: any) => {
      //let message = JSON.parse(messageEvent.data);
      console.log("Received message " + JSON.stringify(message));
      //if (this.user) {
        if (message.type == 'user') {
          //if (message.id === this.user.id) {
            this.updateUser(message);
          //}
        }
      //}
    });*/

  }

  ngOnInit(): void {
    /*this.httpClient.get('/api/bookings', {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result) => {
      this.bookings = result;
    })*/

    
  }

  getUserByEmail() {
    //this.sendAmount = +this.emailForm.value.amount;
    let body = {
      email: this.emailForm.value.email
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.name = result.user.fullName;
        this.id = result.user.id;
        //this.disableTab2 = false;
        //this.activeTab = 2;
      }
    });
  }

  getUserByPhone() {
    //this.sendAmount = +this.phoneForm.value.amount;
    let body = {
      phone: this.phoneForm.value.phone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.name = result.user.fullName;
        this.id = result.user.id;
        //this.disableTab2 = false;
        //this.activeTab = 2;
      }
    });
  }

  getUserById() {
    //this.sendAmount = +this.idForm.value.amount;
    let body = {
      id: this.idForm.value.id
    };
    this.httpClient.post('/api/getuserbyid',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.name = result.user.fullName;
        this.id = result.user.id;
        
        //this.disableTab2 = false;
        //this.activeTab = 2;
      }
    });
  }


  openUser(id: string) {
    this.router.navigate(['/admin-users/' + id]);
  }

  /*updateUser(message: any) {
    console.log('Updating payment');
    //let bookings = this.bookingsSubject.getValue();

    let found = false;
    for (let existingPayment of this.users) {
      if (existingPayment.id == message.id) {
        existingPayment.sender = message.sender;
        existingPayment.receiver = message.receiver;
        existingPayment.type = message.type;
        existingPayment.date = message.date;
        found = true;
      }
    }

    if (!found) {
      let user = { id: message.id, type: message.type, sender: message.sender, receiver: message.receiver, amount: message.amount, date: message.date };
      this.users.unshift(user);
    }

    //this.bookingsSubject.next(bookings);
  }*/

  back() {
    this.router.navigate(['/']);
  }

}
