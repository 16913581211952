<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Transactions</h2>
<div class="input-group">
    <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="loadTransactions()"
        ngbDatepicker
        #d="ngbDatepicker"
    />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
</div>
<div *ngIf="transactions && transactions.length == 0">
    No Transactions yet for this day!
</div>
<div *ngIf="transactions && transactions.length > 0">
    <table class="w-100">
        <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>User</th>
            <th>Type</th>
            <th>Booking</th>
        </tr>
        <tr *ngFor="let transaction of transactions" style="cursor: pointer">
            <td>{{ transaction.date | date:'medium' }}</td>
            <td>{{ transaction.amount | currency:transaction.currency }}</td>
            <td class="ellipsis"><a [routerLink]="'/admin-users/' + transaction.userId">{{ transaction.userDetail?transaction.userDetail.fullName:transaction.userId }}</a></td>
            <td>{{ transaction.type }}</td>
            <td *ngIf="transaction.booking">BOOKING</td>
            <td *ngIf="transaction.booking"><a [routerLink]="'/bookings/' + transaction.booking">{{ transaction.booking }}</a></td>
            <td *ngIf="transaction.referenceType">{{ transaction.referenceType }}</td>
            <td *ngIf="transaction.referenceId"><a [routerLink]="'/' + (transaction.referenceType == 'SEND'?'sends':'bookings') + '/' + transaction.referenceId">{{ transaction.referenceId }}</a></td>
        </tr>
    </table>
    <div *ngIf="transactionsBalance != undefined">Balance: {{ transactionsBalance | currency:'PHP' }}</div>
    <div *ngIf="transactionsIncome">Income: {{ transactionsIncome | currency:'PHP' }}</div>
</div>
<div *ngIf="!transactions">
    Loading Transactions
</div>