<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<div class="d-flex justify-content-center align-items-center w-100 mt-2 mx-2">
  <div *ngIf="send" style="width:100%; max-width: 700px">
    <h2>Send</h2>
    <div>{{ send.id }}</div>
    <div *ngIf="user">
      <span *ngIf="send.user != send.sender && send.user == user.id" class="badge bg-success">Creator</span>
      <span *ngIf="send.sender == user.id" class="badge bg-success">Sender</span>
      <span *ngIf="send.receiver == user.id" class="badge bg-danger">Receiver</span>
    </div>
    <div>Date: {{ send.date | date:'long' }}</div>
    <div>Amount: {{ send.amount | currency:send.currency }}</div>
    <!--div *ngIf="booking.currency !== booking.receiverCurrency">Receiver Amount: {{ booking.receiverAmount | currency:booking.receiverCurrency }}</div-->
    <div>Sender: <a [href]="'/admin-users/' + send.sender">{{ send.senderDetail.fullName }}</a></div>
    <div>Receiver: <a [href]="'/admin-users/' + send.receiver">{{ send.receiverDetail.fullName }}</a></div>
  </div>
  <div *ngIf="!send">
    Loading Send
  </div>
</div>