import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup = new UntypedFormGroup({
    login: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  });

  registerForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(''),
    email: new UntypedFormControl(''),
    phone: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  });

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
  }

  login() {
    this.authService.login(this.form.value.login, this.form.value.password).then((result) => {
      if (result) {
        this.router.navigate(['/']);
      }
    });
  }

  register() {
    this.authService.register(this.registerForm.value.name, this.registerForm.value.email, this.registerForm.value.phone, this.registerForm.value.password).then((result) => {
      if (result) {
        this.router.navigate(['/']);
      }
    });;
  }

}
