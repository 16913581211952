import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  //prod 
  //static kycBucket = 'https://fastsendapi-prod-kycbucket-zcwtkhleebdz.s3.ap-southeast-1.amazonaws.com/';
  //test
  static kycBucket = environment.kycBucket;

  // prod
  //static photoBucket = 'https://fastsendapi-prod-photobucket-1a15vqtcv54c9.s3.ap-southeast-1.amazonaws.com/';
  // test
  static photoBucket = environment.photoBucket;

  static cashInBucket = environment.cashInBucket;


  constructor(private http: HttpClient, private authService: AuthService) {}


  public uploadImage(id: string|undefined, mimeType: string, image: any): Promise<Response> {
    return new Promise<Response>(async (resolve, reject) => {
      /*const formData = new FormData();
      
      formData.append('image', image);*/

      //et imageFile = await image.arrayBuffer;
  
      this.http.post<{uploadURL: string, Key: string}>('/api/uploadkyc', {
        id: id,
        type: mimeType,
        file: 'driverslicense'
      }, {
        headers: {
          Authorization: 'Bearer ' + this.authService.token
        }
      }).subscribe((response) => {
        console.log(response);
        this.http.put<Response>(response.uploadURL, image, {
          headers: {
            'Content-Type': mimeType
          }
        }).subscribe( (result) => {
          resolve(result);
        });
      });
    });
    
  }

  public uploadPhoto(mimeType: string, image: any): Promise<Response> {
    return new Promise<Response>(async (resolve, reject) => {
      /*const formData = new FormData();
      
      formData.append('image', image);*/

      //et imageFile = await image.arrayBuffer;
  
      this.http.post<{uploadURL: string, key: string}>('/api/uploadphoto', {
        type: mimeType,
        file: 'selfie'
      }, {
        headers: {
          Authorization: 'Bearer ' + this.authService.token
        }
      }).subscribe((response) => {
        console.log(response);
        this.http.put<Response>(response.uploadURL, image, {
          headers: {
            'Content-Type': mimeType
          }
        }).subscribe( (result) => {
          resolve(result);
        });
      });
    });
    
  }

  public uploadCashInPhoto(id: string|undefined, mimeType: string, image: any): Promise<Response> {
    return new Promise<Response>(async (resolve, reject) => {
      /*const formData = new FormData();
      
      formData.append('image', image);*/

      //et imageFile = await image.arrayBuffer;
  
      this.http.post<{uploadURL: string, Key: string}>('/api/uploadcashin', {
        id: id,
        type: mimeType,
        file: 'record'
      }, {
        headers: {
          Authorization: 'Bearer ' + this.authService.token
        }
      }).subscribe((response) => {
        console.log(response);
        this.http.put<Response>(response.uploadURL, image, {
          headers: {
            'Content-Type': mimeType
          }
        }).subscribe( (result) => {
          resolve(result);
        });
      });
    });
    
  }
}
