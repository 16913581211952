<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Send Cash</h2>
    <div *ngIf="!sent">
        <form [formGroup]="form" (ngSubmit)="sendBooking()">
            <button *ngIf="user.agent" (click)="assistedBooking = !assistedBooking" type="button">Assisted Booking</button>
            <div [(ngbCollapse)]="!assistedBooking">
                <h3>Sender</h3>
                <div class="form-group">
                    <label>Email</label>
                    <input type="text" formControlName="senderEmail" class="form-control" (change)="getSenderByEmail()">
                    <span *ngIf="senderByEmail">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div>-- OR --</div>
                <div class="form-group">
                    <label>Phone</label>
                    <input type="text" formControlName="senderPhone" class="form-control" (change)="getSenderByPhone()">
                    <span *ngIf="senderByPhone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" formControlName="senderName" class="form-control">
                </div>
            </div>

            <h3>Receiver</h3>
            <div *ngIf="!payment" class="form-group">
                <label>Email</label>
                <input type="text" formControlName="email" class="form-control" (change)="getReceiverByEmail()">
                <span *ngIf="receiverByEmail">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                </span>
            </div>
            <div *ngIf="!payment">-- OR --</div>
            <div *ngIf="!payment" class="form-group">
                <label>Phone</label>
                <input type="text" formControlName="phone" class="form-control" (change)="getReceiverByPhone()">
                <span *ngIf="receiverByPhone">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path
                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                </span>
            </div>
            <hr *ngIf="!payment" />
            <div *ngIf="payment" class="form-group">
                <label>Payment</label>
                <div>{{ payment }}</div>
            </div>
            <div *ngIf="!payment" class="form-group">
                <label>Name</label>
                <input type="text" formControlName="name" class="form-control">
            </div>
            <div *ngIf="!payment" class="form-group">
                <label>Age</label>
                <input type="text" formControlName="age" class="form-control">
            </div>
            <div *ngIf="!amount" class="form-group">
                <label>Amount</label>
                <input type="text" formControlName="amount" class="form-control">
            </div>
            <div *ngIf="!amount" class="form-group">
                <label>Currency</label>
                <select formControlName="currency" class="form-control">
                    <option *ngFor="let currency of currencies" [ngValue]="currency.symbol">{{currency.symbol}} - {{
                        currency.text }}</option>
                </select>
            </div>
            <div *ngIf="amount" class="form-group">
                <label>Amount</label>
                <div>{{ amount }}</div>
            </div>
            <div class="form-group">
                <label>Type</label>
                <select formControlName="type" class="form-control">
                    <option *ngIf="!payment" ngValue="CASH_TO_CASH">Cash To Cash</option>
                    <option ngValue="CASH_TO_WALLET">Cash to E-Wallet</option>
                    <option *ngIf="!payment" ngValue="WALLET_TO_CASH">E-Wallet to Cash</option>
                    <option ngValue="WALLET_TO_WALLET">E-Wallet to E-Wallet</option>
                </select>
            </div>
            <div *ngIf="!payment" class="form-group">
                <label>Purpose</label>
                <input type="text" formControlName="purpose" class="form-control">
            </div>
            <div *ngIf="!payment" class="form-group">
                <label>Location</label>
                <input type="text" formControlName="location" class="form-control">
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Create Booking">
            </div>
        </form>
    </div>
    <div *ngIf="sent">
        <form>
            <div class="form-group">
                <label>Amount</label>
                <div>{{ response.amount | currency: response.currency }}</div>
            </div>
            <div class="form-group">
                <label>Sender Agent Fee</label>
                <div>{{ response.senderAgentFee | currency: response.currency}}</div>
            </div>
            <div class="form-group">
                <label>Receiver Agent Fee</label>
                <div>{{ response.receiverAgentFee | currency: response.currency}}</div>
            </div>
            <div class="form-group">
                <label>Platform Fee</label>
                <div>{{ response.platformFee | currency: response.currency}}</div>
            </div>
            <div class="form-group">
                <label>Total</label>
                <div>{{ response.total | currency: response.currency}}</div>
            </div>
            <div class="form-group">
                <label>Sending Agents Found</label>
                <div>{{ response.senderAgentsOffered }}</div>
            </div>
            <div class="form-group">
                <label>Receiving Agents Found</label>
                <div>{{ response.receiverAgentsOffered }}</div>
            </div>
            <button class="btn btn-primary" (click)="sendConfirmation()">Send Confirmation</button>
        </form>

        <form [formGroup]="updateBookingForm" (ngSubmit)="updateBooking()">
            <div class="form-group">
                <label>Sender Radius</label>
                <select formControlName="senderRadius" class="form-control">
                    <option ngValue="2500">2500</option>
                    <option ngValue="5000">5000</option>
                    <option ngValue="7500">7500</option>
                    <option ngValue="10000">10000</option>
                </select>
            </div>
            <div class="form-group">
                <label>Receiver Radius</label>
                <select formControlName="receiverRadius" class="form-control">
                    <option ngValue="2500">2500</option>
                    <option ngValue="5000">5000</option>
                    <option ngValue="7500">7500</option>
                    <option ngValue="10000">10000</option>
                </select>
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Update Booking">
            </div>

        </form>

    </div>
    <div *ngIf="sent && sentConfirmation">Confirmation Sent</div>
</div>