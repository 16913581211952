import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { SendScanComponent } from '../send-scan/send-scan.component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as uuid from 'uuid';

@Component({
  selector: 'app-create-send',
  templateUrl: './create-send.component.html',
  styleUrls: ['./create-send.component.scss']
})
export class CreateSendComponent implements OnInit {

  radio: UntypedFormControl = new UntypedFormControl('EMAIL');

  emailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    amount: new UntypedFormControl('0')
  });

  phoneForm: UntypedFormGroup = new UntypedFormGroup({
    phone: new UntypedFormControl(''),
    amount: new UntypedFormControl('0')
  });

  idForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl(''),
    amount: new UntypedFormControl('0')
  });

  confirmForm: UntypedFormGroup = new UntypedFormGroup({
    
  });

  sent = false;
  activeTab = 1;
  disableTab2 = true;
  disableTab3 = true;
  sendAmount?: number;
  sendName?: String;
  sendId?: String;
  sendDisabled = true;
  id: String;
  
  constructor(private httpClient: HttpClient, private router: Router, private modalService: NgbModal, private authService: AuthService) {
    this.id = uuid.v4();
  }

  ngOnInit(): void {
  }

  getUserByEmail() {
    this.sendAmount = +this.emailForm.value.amount;
    let body = {
      email: this.emailForm.value.email
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sendName = result.user.fullName;
        this.sendId = result.user.id;
        this.disableTab2 = false;
        this.activeTab = 2;
      }
    });
  }

  getUserByPhone() {
    this.sendAmount = +this.phoneForm.value.amount;
    let body = {
      phone: this.phoneForm.value.phone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sendName = result.user.fullName;
        this.sendId = result.user.id;
        this.disableTab2 = false;
        this.activeTab = 2;
      }
    });
  }

  getUserById() {
    this.sendAmount = +this.idForm.value.amount;
    let body = {
      id: this.idForm.value.id
    };
    this.httpClient.post('/api/getuserbyid',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sendName = result.user.fullName;
        this.sendId = result.user.id;
        
        this.disableTab2 = false;
        this.activeTab = 2;
      }
    });
  }

  updateSendDisabled() {
    if (this.radio.value == 'EMAIL') {
      if (this.emailForm.value.email && this.emailForm.value.amount > 0) {
        this.sendDisabled = false;
      } else {
        this.sendDisabled = true;
      }
    } else if (this.radio.value == 'PHONE') {
      if (this.phoneForm.value.phone && this.phoneForm.value.amount > 0) {
        this.sendDisabled = false;
      } else {
        this.sendDisabled = true;
      }
    } else {
      if (this.idForm.value.id && this.idForm.value.amount > 0) {
        this.sendDisabled = false;
      } else {
        this.sendDisabled = true;
      }
    }
  }
  
  scan() {
    //this.router.navigate(['/scan']);
    this.modalService.open(SendScanComponent).result.then((result: any) => {
      let code = `${result}`;
      this.idForm.patchValue({id: code });
    }, (reason: any) => {
      //this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  send() {
    let body = {
      id: this.id,
      receiver: this.sendId,
      amount: this.sendAmount
    };
    this.httpClient.post('/api/send', body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.disableTab3 = false;
        this.activeTab = 3;
        this.sent = true;
      }
    });
    
  }

  back() {
    this.router.navigate(['/']);
  }

}
