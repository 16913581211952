<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<div class="d-flex justify-content-center align-items-center w-100 mt-2 mx-2">
    <div *ngIf="kyc" style="width:100%; max-width: 700px">
        <h2>KYC</h2>
        <div>KYC Id: {{ kyc.id }}</div>
        <div>User: {{ kyc.userId }}</div>
        <div>Status: {{ kyc.approved == undefined?'PENDING':kyc.approved?'APPROVED':'DECLINED' }}</div>
        <div *ngIf="kyc.message">Message: {{ kyc.message }}</div>
        <div>User: <a [routerLink]="'/admin-users/' + kyc.userId">{{ kyc.userDetail.fullName }}</a></div>
        <div>Type: {{ kyc.type }}</div>
        <div>Date Started: {{ kyc.date }}</div>
        <div>Date Submitted: {{ kyc.submittedDate }}</div>
        <h3>Fields</h3>
        <div *ngFor="let field of kyc.fields  | keyvalue">{{ field.key }}: {{ field.value }}</div>
        <h3>Uploads</h3>
        <div *ngFor="let upload of kyc.uploads"><a [href]="bucket + upload ">{{ upload }}</a></div>
        
        <button class="btn btn-primary" (click)="approve()">Approve</button>
        <button class="btn btn-primary ml-2" (click)="disapprove()">Disapprove</button>
        Message: <input type="text" [(ngModel)]="message">
    </div>
    <div *ngIf="!kyc">
        Loading KYC
    </div>
</div>