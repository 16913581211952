import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-create-cash-in',
  templateUrl: './create-cash-in.component.html',
  styleUrls: ['./create-cash-in.component.scss']
})
export class CreateCashInComponent {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }


  back() {
    this.router.navigate(['/']);
  }
}
