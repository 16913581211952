<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>KYC Pending</h2>
<div class="input-group">
    <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="loadKycs()"
        ngbDatepicker
        #d="ngbDatepicker"
    />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
</div>
<div *ngIf="kycs && kycs.length == 0">
    No Payments yet for this day!
</div>
<table *ngIf="kycs && kycs.length > 0" class="w-100">
    <tr>
        <th>Date</th>
        <th>Name</th>
        <th>Type</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let kyc of kycs" (click)="openKyc(kyc)" style="cursor: pointer">
        <!--td><a [routerLink]="'/kyc/' + kyc.userId + '!' + kyc.id">{{ kyc.id }}</a></td-->
        <td>{{ kyc.date | date:'medium' }}</td>
        <td>{{ kyc.userDetail.fullName }}</td>
        <td>{{ kyc.type }}</td>
        <td>{{ kyc.approved == undefined?'PENDING':(kyc.approved?'APPROVED':'DENIED') }}</td>
    </tr>
</table>
<div *ngIf="!kycs">
    Loading KYCs
</div>