<header id="top">
  <div class="wrapper">
    <h1 class="logo"><a routerLink="/">FastSend</a></h1>
    <nav>
      <ul id="navigation">
        <li><a href="#personal">PERSONAL</a></li>
        <li><a routerLink="/agent">AGENTS</a></li>
        <li><a href="#services">SERVICES</a></li>
        <li><a href="#help">HELP & FAQ</a></li>
        <li *ngIf="!isAuthenticated"><a class="nav-cta" routerLink="/login">SIGN UP</a></li>
        <li *ngIf="!isAuthenticated"><a class="nav-cta" routerLink="/login">LOG IN</a></li>
        <li *ngIf="isAuthenticated"><a class="nav-cta" routerLink="/logout">LOG OUT</a></li>
      </ul>
    </nav>
  </div>
</header>

<div *ngIf="isAuthenticated" class="sticky">
  <div style="display: flex; overflow-x: scroll">
    <div *ngFor="let booking of bookings" class="card m-1 booking" (click)="openBooking(booking)" [ngClass]="booking.deleted?'deleted':''">
        <div>
            <span *ngIf="booking.user != booking.sender && booking.user == user.id" class="badge bg-success">Creator</span>
            <span *ngIf="booking.sender == user.id" class="badge bg-success">Sender</span>
            <span *ngIf="booking.senderAgent == user.id" class="badge bg-info">Sender Agent</span>
            <span *ngIf="booking.receiverAgent == user.id" class="badge bg-warning">Receiver Agent</span>
            <span *ngIf="booking.receiver == user.id" class="badge bg-danger">Receiver</span>
        </div>
        <div *ngIf="booking.name">Receiver: {{ booking.name }}</div>
        <div *ngIf="booking.amount">Amount: {{ booking.amount | currency:booking.currency }}</div>
        <app-timeline [events]="[{title:'Sending Agent',done: booking.senderAgent != null},{title:'Receiving Agent', done: booking.receiverAgent != null},{title:'Cash Picked Up',done: booking.cashPickedUp},{title:'Cash Dropped Off',done: booking.cashDroppedOff}]"></app-timeline>
    </div>
    <div *ngIf="!bookings">
    Loading Bookings
    </div>
</div>
</div>
<router-outlet></router-outlet>
<footer>
  <div class="wrapper">
    <!--
    <a class="logo" href="#">Chase</a>
    <div class="footer-left">
      <p class="copyright">Copyright 2021 &copy; Chase</p>
      <p class="footer-links"><a href="#">Contact Us</a> I <a href="#">Terms &amp; Conditions</a> I<a href="#">Privacy</a></p>
    </div>
    -->
    <div class="footer-center">
      <a href="#" class="social facebook">Facebook</a>
      <a href="#" class="social twitter">Facebook</a>
      <a href="#" class="social google">Facebook</a>
    </div>
    <div class="clear"></div>
  </div>
</footer>
