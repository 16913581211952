import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OfferComponent } from './components/offer/offer.component';
import { AuthService } from './services/auth.service';
import { BookingsService } from './services/bookings.service';
import { LocationService } from './services/location.service';
import { UserService } from './services/user.service';
import { WsService } from './services/ws.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'fastsend-frontend';

  isAuthenticated = false;

  user: any;

  bookings?: any;

  constructor(private userService: UserService, private modalService: NgbModal, private router: Router, private bookingsService: BookingsService, private wsService: WsService, private locationService: LocationService, private authService: AuthService) {
    /*let token = localStorage.getItem('token');
    if (token) {
      let user = localStorage.getItem('user');
      if (user) {
        this.authService.token = token;
        this.authService.currentUserSubject.next(user);
        this.authService.isAuthenticatedSubject.next(true);
      }
    }*/

    this.authService.isAuthenticatedSubject.subscribe(
      (isAuthenticated) => {
        this.isAuthenticated = isAuthenticated;
      }
    )

    this.userService.currentUserSubject.subscribe(
      (user) => {
        this.user = user;
        if (this.user.cash == undefined) {
          this.user.cash = 0;
        }
        if (this.user.balance == undefined) {
          this.user.balance = 0;
        }
      }
    );

    this.bookingsService.bookingsSubject.subscribe((bookings) => {
      console.log('AppComponent got updated bookings');
      this.bookings = bookings;
    });
    
    this.wsService.isConnected.subscribe((isConnected) => {
      if (isConnected) {
        this.wsService.observable.subscribe((message: any) => {
          //let message = JSON.parse(messageEvent.data);
          console.log("Received message " + JSON.stringify(message));
          if (message.type == 'offer') {
            if (this.user && message.agent == this.user.id) {
              const modalRef = this.modalService.open(OfferComponent);
              modalRef.componentInstance.offer = message;
              modalRef.result.then((result: any) => {
                if (result == true) {
    
                }
              }, (reason: any) => {
                
              });
              //window.alert('Offer received');
            }
          }
        });
      }
    });
    
  }

  openBooking(booking: any) {
    this.router.navigate(['/bookings/' + booking.id]);
  }
  
  /*openBookings() {
    this.router.navigate(['/bookings']);
  }

  newBooking() {
    this.router.navigate(['/create-booking']);
  }

  newPayment() {
    this.router.navigate(['/create-payment']);
  }

  openPayments() {
    this.router.navigate(['/payments']);
  }

  openOffers() {
    this.router.navigate(['/offers']);
  }

  newBiller() {
    this.router.navigate(['/create-biller']);
  }

  openBillers() {
    this.router.navigate(['/billers']);
  }

  payBill() {
    this.router.navigate(['/paybill']);
  }

  createSuperAgent() {
    this.router.navigate(['/create-super-agent']);
  }

  openSuperAgents() {
    this.router.navigate(['/super-agents']);
  }*/
}
