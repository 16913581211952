<div>
    <button class="btn btn-primary" (click)="back()">&lt; Back</button>
    <h2>Pay Bill</h2>
    <div *ngIf="!sent">
        <form [formGroup]="form" (ngSubmit)="send()">
            <button *ngIf="user.agent" (click)="assistedPayment = !assistedPayment" type="button">Assisted Bill Payment</button>
            <div [(ngbCollapse)]="!assistedPayment">
                <h3>Sender</h3>
                <div class="form-group">
                    <label>Email</label>
                    <input type="text" formControlName="senderEmail" class="form-control" (change)="getSenderByEmail()">
                    <span *ngIf="senderByEmail">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div>-- OR --</div>
                <div class="form-group">
                    <label>Phone</label>
                    <input type="text" formControlName="senderPhone" class="form-control" (change)="getSenderByPhone()">
                    <span *ngIf="senderByPhone">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                    </span>
                </div>
                <div class="form-group">
                    <label>Name</label>
                    <input type="text" formControlName="senderName" class="form-control">
                </div>
            </div>
            <div class="form-group">
                <label>Biller</label>
                <select formControlName="biller" class="form-control" (ngModelChange)="onChange()">
                    <option *ngFor="let biller of billers" [ngValue]="biller.id">{{ biller.name }}</option>
                </select>
            </div>
            <div *ngIf="biller && biller.bills" class="form-group">
                <label>Bill</label>
                <select formControlName="bill" class="form-control" (ngModelChange)="onBillChange()">
                    <option *ngFor="let bill of biller.bills" [ngValue]="bill.id">{{ bill.name }}</option>
                </select>
            </div>
            <div *ngIf="bill && bill.fields">
                <div *ngFor="let field of bill.fields">
                    <label>{{ field.label }}</label>
                    <input type="text" [formControlName]="field.id" class="form-control">
                </div>
            </div>
            <!--div *ngIf="!bill" class="form-group">
                <label>Reference Number</label>
                <input type="text" formControlName="referenceNumber" class="form-control">
            </div-->
            <div class="form-group">
                <label>Amount</label>
                <input type="text" formControlName="amount" class="form-control">
            </div>
            <div class="form-group">
                <label>Type</label>
                <select formControlName="bookingType" class="form-control">
                    <!--option *ngIf="!payment" ngValue="CASH_TO_CASH">Cash To Cash</option-->
                    <option ngValue="CASH_TO_WALLET">Cash to E-Wallet</option>
                    <!--option *ngIf="!payment" ngValue="WALLET_TO_CASH">E-Wallet to Cash</option-->
                    <option ngValue="WALLET_TO_WALLET">E-Wallet to E-Wallet</option>
                    <option ngValue="CASH_TO_PAYMENT">Cash to Payment</option>
                    <option ngValue="WALLET_TO_PAYMENT">Wallet to Payment</option>
                </select>
            </div>
            <div class="p-4 text-center">
                <input class="btn btn-primary" type="submit" value="Pay Bill">
            </div>
        </form>
    </div>
    <div *ngIf="sent">
        Sent
    </div>
</div>