import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Currencies } from 'src/app/models/currencies';
import { AuthService } from 'src/app/services/auth.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-admin-cash-in',
  templateUrl: './admin-cash-in.component.html',
  styleUrls: ['./admin-cash-in.component.scss']
})
export class AdminCashInComponent implements OnInit {

  types = [
    {id:'ADMIN_CASHIN_GCASH',label:"G-Cash"},
    {id:'ADMIN_CASHIN_MAYA',label:"Maya"},
    {id:'ADMIN_CASHIN_CHECK',label:"Check"},
    {id:'ADMIN_CASHIN_TEST',label:"Complimentary"},
  ];

  currencies: {symbol: string, text: string}[];
  
  sent = false;
  currency = 'PHP';
  receiver?: string;
  name = '';
  id: string;
  
  radio: UntypedFormControl = new UntypedFormControl('EMAIL');

  emailForm: UntypedFormGroup = new UntypedFormGroup({
    email: new UntypedFormControl('')
  });

  phoneForm: UntypedFormGroup = new UntypedFormGroup({
    phone: new UntypedFormControl('')
  });

  idForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl('')
  });

  form: UntypedFormGroup = new UntypedFormGroup({
    currency: new UntypedFormControl('PHP'),
    amount: new UntypedFormControl('0'),
    //receiver: new UntypedFormControl(''),
    type: new UntypedFormControl('GCASH'),
    reference: new UntypedFormControl('')
  });

  setCurrency(event: any) {
    this.currency = this.form.value.currency;
  }

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService) {
    this.currencies = Currencies.currencies;
    this.id = uuid.v4();
  }

  ngOnInit(): void {
  }

  getUserByEmail() {
    //this.sendAmount = +this.emailForm.value.amount;
    let body = {
      email: this.emailForm.value.email
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.name = result.user.fullName;
        this.receiver = result.user.id;
        //this.disableTab2 = false;
        //this.activeTab = 2;
      }
    });
  }

  getUserByPhone() {
    //this.sendAmount = +this.phoneForm.value.amount;
    let body = {
      phone: this.phoneForm.value.phone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.name = result.user.fullName;
        this.receiver = result.user.id;
        //this.disableTab2 = false;
        //this.activeTab = 2;
      }
    });
  }

  getUserById() {
    //this.sendAmount = +this.idForm.value.amount;
    let body = {
      id: this.idForm.value.id
    };
    this.httpClient.post('/api/getuserbyid',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.name = result.user.fullName;
        this.receiver = result.user.id;
        
        //this.disableTab2 = false;
        //this.activeTab = 2;
      }
    });
  }

  cashIn() {
    let body = {
      id: this.id,
      currency: this.form.value.currency,
      amount: +this.form.value.amount,
      receiver: this.receiver,
      type: this.form.value.type,
      reference: this.form.value.reference,
    };
    this.httpClient.post('/api/admin/cashin', body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      this.sent = true;
      //if (result.result === 0) {
      //}
    });
  }

  back() {
    this.router.navigate(['/']);
  }

}
