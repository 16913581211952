<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Payments</h2>
<div class="input-group">
    <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="dp"
        [(ngModel)]="model"
        (ngModelChange)="loadPayments()"
        ngbDatepicker
        #d="ngbDatepicker"
    />
    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
</div>
<div *ngIf="payments && payments.length == 0">
    No Payments yet for this day!
</div>
<table *ngIf="payments && payments.length > 0" class="w-100">
    <tr>
        <th>Date</th>
        <th>Type</th>
        <th>Sender</th>
        <th>Receiver</th>
        <th>Amount</th>
        <th>Status</th>
    </tr>
    <tr *ngFor="let payment of payments" (click)="openPayment(payment.id)" style="cursor: pointer">
        <td>{{ payment.date | date:'medium' }}</td>
        <td>{{ payment.type }}</td>
        <td class="ellipsis">{{ payment.senderDetail?payment.senderDetail.fullName:payment.sender }}</td>
        <td class="ellipsis">{{ payment.receiverDetail?payment.receiverDetail.fullName:payment.receiver }}</td>
        <td>{{ payment.amount }}</td>
        <td>{{ payment.complete?'Complete':'' }}{{ payment.failed?'Failed':'' }}</td>
    </tr>
</table>
<div *ngIf="!payments">
    Loading Payments
</div>