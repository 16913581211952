<section id="banner"><a name="home"></a>
    <div class="wrapper">
        <div class="image align-right">
            <img src="/assets/img/home/cape.png" alt="FastSend Super Mobile App" class="align-left" >
        </div>
        <h2>THE PHILIPPINES'<br> REMITTANCE SUPER APP </h2>
        <div class="buttons">
            <a href="#getstarted" class="button-1">GET STARTED</a>
            <br>
            <a href="#applestorelink" class="button-2"><img src="/assets/img/home/applestore.png" alt="apple store link"></a>
            <a href="#playstorelink" class="button-3"><img src="/assets/img/home/googlestore.png" alt="google store link"></a>
        </div>
    </div>
</section>

<section class="text-section">	
    <div class="wrapper">
        <div class="image align-left">
            <img src="/assets/img/home/piggybank.png" alt="piggy bank" class="align-left">
        </div>
        <div class="text align-left">
            <h4>MADALING MAGPADALA NG PERA</h4>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis gravida blandit lacus a laoreet. Nullam sollicitudin urna metus, at malesuada orci fringilla in. Curabitur eleifend risus vitae ligula semper scelerisque. Nulla sed nisl nulla.</p>
            <p>Fusce interdum suscipit ipsum, quis mattis metus posuere sit amet. Mauris rutrum imperdiet tortor.</p>
        </div>
        <div class="clear"></div>	
    </div>
</section>


<section class="orange text-section">
    <div class="text align-center">
        <h3>Need more help?</h3>
        <a href="#contactus" class="button-4">CONTACT US</a>
        <a href="#sendticket" class="button-4">SEND A TICKET</a>
    </div>
</section>