import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';
import * as uuid from 'uuid';

@Component({
  selector: 'app-pay-bill',
  templateUrl: './pay-bill.component.html',
  styleUrls: ['./pay-bill.component.scss']
})
export class PayBillComponent implements OnInit {

  id: string;
  sender?: string;
  senderByEmail = false;
  senderByPhone = false;
  senderName?: string;
  billers?: any;
  biller?: any;
  bill?: any;

  user?: any;

  assistedPayment = false;
  sent = false;
  
  form: UntypedFormGroup = new UntypedFormGroup({
    senderEmail: new UntypedFormControl(''),
    senderPhone: new UntypedFormControl(''),
    senderName: new UntypedFormControl(''),
    biller: new UntypedFormControl(''),
    bill: new UntypedFormControl(''),
    referenceNumber: new UntypedFormControl(''),
    amount: new UntypedFormControl(''),
    bookingType: new UntypedFormControl('CASH_TO_WALLET')
  });

  constructor(private httpClient: HttpClient, private wsService: WsService, private router: Router, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) { 
    this.billers = this.route.snapshot.data['billers'];
    this.id = uuid.v4();
    this.userService.currentUserSubject.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {

  }

  getSenderByEmail() {
    let body = {
      email: this.form.value.senderEmail
    };
    this.httpClient.post('/api/getuserbyemail',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByEmail = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({senderName: result.user.fullName});
          //this.senderName = result.user.fullName;
        //}
      } 
    });
  }

  getSenderByPhone() {
    let body = {
      phone: this.form.value.senderPhone
    };
    this.httpClient.post('/api/getuserbyphone',body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.sender = result.user.id;
        this.senderByPhone = true;
        //if (this.form.value.name == '') {
          console.log('Settings form name to ' + result.user.fullName);
          this.form.patchValue({senderName: result.user.fullName});
          //this.senderName = result.user.fullName;
        //}
      } 
    });
  }

  send() {

    let selectedBiller: any;
    let selectedBill: any;
    let billerId = this.form.value.biller;
    let sender = this.user.id;

    if (this.assistedPayment) {
      sender = this.sender;
    }

    for (let biller of this.billers) {
      if (biller.id == billerId) {
        selectedBiller = biller;

        let billId = this.form.value.bill;
        if (selectedBiller.bills) {
          for (let bill of selectedBiller.bills) {
            if (bill.id == billId) {
              selectedBill = bill;
            }
          }
        } else {
          selectedBill = null;
        }
      }
    }

    if (selectedBiller) {
      let fields = [];
      
      if (selectedBill && selectedBill.fields && selectedBill.fields.length > 0) {
        for (let field of selectedBill.fields) {
          let value = this.form.value[field.id];
          fields.push({id: field.id, value: value});
        }
      }

      let payment: any = {
        id: this.id,
        type: 'PAYMENT_TO_BILLER',
        amount: this.form.value.amount,
        sender: sender,
        receiver: selectedBiller.receiver,
        billerId: billerId,
        bill: this.form.value.bill,
        startDate: new Date().toISOString(), //this.startDate.toISOString(),
        intervals: 1,
        intervalPeriod: 1,
        intervalUnit: 'MONTH',
        fields: fields
      };

      if (this.assistedPayment) {
        payment.senderAgent = this.user.id;
      }

      this.httpClient.post('/api/payments',payment, {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((paymentResult: any) => {
        let booking: any = {
          type: this.form.value.bookingType,
          name: selectedBiller.name,
          amount: this.form.value.amount,
          receiver: selectedBiller.receiver,
          sender: sender,
          payment: paymentResult.id
        };

        if (this.assistedPayment) {
          booking.senderAgent = this.user.id;
        }
        
        this.httpClient.post('/api/bookings',booking, {
          headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
        }).subscribe((result: any) => {
          this.sent = true;
        });
      });

    }
  }

  onChange() {
    console.log("Biller changed");
    let billerId = this.form.value.biller;
    let selectedBiller;
    let selectedBill;
    for (let biller of this.billers) {
      if (biller.id == billerId) {
        console.log("Selected biller set");
        selectedBiller = biller;

        if (this.form.value.bill) {
          let billId = this.form.value.bill;
          if (selectedBiller.bills) {
            for (let bill of selectedBiller.bills) {
              if (bill.id == billId) {
                selectedBill = bill;
              }
            }
          } else {
            selectedBill = null;
          }
        }
      }
    }
    if (this.bill) {
      /*for (let field of this.biller.fields) {
        this.form.removeControl(field.id);
      }*/
    }
    console.log("Set biller");
    this.biller = selectedBiller;
    if (selectedBill) {  
      if (selectedBiller.bills) {
        //this.bill = selectedBiller.bills[0].id;
      }
      
      /*for (let field of this.biller.fields) {
        this.form.addControl(field.id, new FormControl(''));
      }*/
    }
    
  }

  onBillChange() {
    console.log("Bill changed");
    let billId = this.form.value.bill;
    if (this.biller.bills) {
      for (let bill of this.biller.bills) {
        if (bill.id == billId) {
          if (this.bill) {
            for (let field of this.bill.fields) {
              this.form.removeControl(field.id);
            }
          }

          for (let field of bill.fields) {
            this.form.addControl(field.id, new UntypedFormControl(''));
          }
          this.bill = bill;
        }
      }
    } else {
      this.bill = null;
    }
  }

  back() {
    this.router.navigate(['/pay-bill']);
  }
}
