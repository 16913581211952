<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Cash-In using G-Cash</h2>
<div *ngIf="step == 1">
    <form [formGroup]="form" (ngSubmit)="cashIn()">
        <div class="form-group">
            <label>Amount</label>
            <div class="input-group mb-3">
                <span class="input-group-text" id="basic-addon1">&#8369;</span>
                <input type="text" formControlName="amount" class="form-control" aria-describedby="basic-addon1">
            </div>
        </div>
        <!--div class="form-group">
            <label>Receiver</label>
            <input type="text" formControlName="receiver" class="form-control">
        </div>
        <div class="form-group">
            <label>Type</label>
            <select formControlName="type" class="form-control">
                <option *ngFor="let type of types" [ngValue]="type.id">{{ type.label }}</option>
            </select>
        </div-->
        <div class="form-group">
            <label>Reference</label>
            <input type="text" formControlName="reference" class="form-control">
        </div>
        <div class="p-4 text-center">
            <input class="btn btn-primary" type="submit" value="Cash-In">
        </div>
    </form>
</div>
<div *ngIf="step == 2">
    <label class="image-upload-container btn btn-bwm">
        <span>Select Image</span>
        <input #imageInput
               type="file"
               accept="image/*"
               (change)="processFile(imageInput)">
    </label>
</div>
<div *ngIf="step == 3">
    <button class="btn btn-large" (click)="submit()">Submit</button>
</div>
<div *ngIf="step == 4">
    Submitted
</div>