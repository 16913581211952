<ul class="cards">
    <li><a routerLink="/create-booking"><img src="/assets/img/dashboard/send_money.svg">Send Cash</a></li>
    <li><a routerLink="/cash-in"><img src="/assets/img/dashboard/cash_in.svg">Cash-In</a></li>
    <li><a routerLink="/pay-bill"><img src="/assets/img/dashboard/pay_bill.svg">Pay Bill</a></li>
    <li><a routerLink="/cash-out">Cash-Out</a></li>
    <li><a routerLink="/buy-load"><img src="/assets/img/dashboard/buy_load.svg">Buy Load</a></li>
    <li><a routerLink="/bookings">Bookings</a></li>
    <li><a routerLink="/create-payment">Schedule Payments</a></li>
    <li><a routerLink="/payments">Payments</a></li>
    <li *ngIf="user && user.agent"><a routerLink="/offers">Offers</a></li>
    <li><a routerLink="/super-agents">Super Agents</a></li>
    <li><a routerLink="/selfie">Selfie</a></li>
       
    
    <li><a routerLink="/create-kyc">Create KYC</a></li>
    <li><a routerLink="/agents">Agents</a></li>
    <li *ngIf="user && user.admin"><a routerLink="/admin-cash-in">Admin Cash-In</a></li>
    <li *ngIf="user && user.admin"><a routerLink="/admin-cash-out">Admin Cash-Out</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/kycs">Manager KYC</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-bookings">Manager Bookings</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-payments">Manager Payments</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-usercashins">Manager User Cash-Ins</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-transactions">Manager Transactions</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-photos">Manager Photos</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-users">Manager Users</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/create-super-agent">Manager Create Super Agent</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-support">Manager Support</a></li>
    <li *ngIf="user && (user.admin || user.manager)"><a routerLink="/admin-totals">Manager Totals</a></li>
    <li *ngIf="user && user.admin"><a routerLink="/create-biller">Admin Create Biller</a></li>
    <li *ngIf="user && user.admin"><a routerLink="/create-biller-category">Admin Create Biller Category</a></li>
    <li *ngIf="user && user.admin"><a routerLink="/billers">Admin Billers</a></li>
    <li *ngIf="user && user.admin"><a routerLink="/biller-categories">Admin Biller Categories</a></li>
</ul>
