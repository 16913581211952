<div class="d-lg-flex flex-row justify-content-around mb-1" [ngClass]="{'d-none':user.agent}" style="margin-left: auto; margin-right: auto">
    <app-wallet></app-wallet>
    <app-cash *ngIf="user.agent"></app-cash>
</div>
<div *ngIf="isAuthenticated && user.agent">
    <ul ngbNav #nav="ngbNav" class="d-lg-none nav-pills justify-content-center">
        <li ngbNavItem>
            <a ngbNavLink>Wallet</a>
            <ng-template ngbNavContent>
                <app-wallet></app-wallet>
            </ng-template>
        </li>
        <li *ngIf="user.agent" ngbNavItem>
            <a ngbNavLink>Cash</a>
            <ng-template ngbNavContent><app-cash></app-cash></ng-template>
        </li>
    </ul>
    <div class="d-lg-none" [ngbNavOutlet]="nav"></div>
</div>
<div class="container">
    <div *ngIf="isAuthenticated"><router-outlet></router-outlet></div>
</div>
<div *ngIf="!isAuthenticated"><app-home></app-home></div>
