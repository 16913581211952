<div class="bg-gray-50">
    <form [formGroup]="form" (ngSubmit)="login()" style="margin: 0 auto; width: 400px" class="bg-white">
        <div class="form-group" >
            <label>Email or Phone Number</label>
            <input
                type="text"
                formControlName="login"
                class="form-control"
            />
        </div>
        <div class="form-group">
            <label>Password</label>
            <input
                type="password"
                formControlName="password"
                class="form-control"
            />
        </div>
        <div class="p-4 text-center">
            <button type="submit" class="btn btn-primary">Login</button>
        </div>
    </form>
</div>

<div class="register-form">
    <form [formGroup]="registerForm" (ngSubmit)="register()" style="margin: 0 auto; width: 400px">
        <div class="form-group">
            <label>Name</label>
            <input
                type="text"
                formControlName="name"
                class="form-control"
            />
        </div>
        <div class="form-group">
            <label>Email</label>
            <input
                type="text"
                formControlName="email"
                class="form-control"
            />
        </div>
        <div class="form-group">
            <label>Phone</label>
            <input
                type="text"
                formControlName="phone"
                class="form-control"
            />
        </div>
        <div class="form-group">
            <label>Password</label>
            <input
                type="password"
                formControlName="password"
                class="form-control"
            />
        </div>
        <div class="p-4 text-center">
            <button type="submit" class="btn btn-primary">Register</button>
          </div>
    </form>
</div>
