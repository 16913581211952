import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-cash-in-gcash',
  templateUrl: './cash-in-gcash.component.html',
  styleUrls: ['./cash-in-gcash.component.scss']
})
export class CashInGcashComponent implements OnInit {

  id?: string;

  step = 1;
  
  form: UntypedFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl('0'),
    reference: new UntypedFormControl('')
  });

  constructor(private httpClient: HttpClient, private router: Router, private authService: AuthService, private imageService: ImageService) { }

  ngOnInit(): void {
  }

  cashIn() {
    let body = {
      amount: +this.form.value.amount,
      receiver: this.form.value.receiver,
      type: 'GCASH',
      reference: this.form.value.reference,
    };
    this.httpClient.post('/api/cashin', body, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.id = result.id;
        this.step = 2;
      }
    });
  }

  processFile(imageInput: any) {
    const file: File = imageInput.files[0];
    let mimeType = file.type;
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {

      //this.selectedFile = new ImageSnippet(event.target.result, file);
      var buffer = this.dataUriToBlob(event.target.result);
      this.imageService.uploadCashInPhoto(this.id, mimeType, buffer).then(
        (res) => {
          this.step = 3;
        },
        (err) => {
        
        })
    });

    reader.readAsDataURL(file);
    //reader.readAsArrayBuffer(file);
  }

  private dataUriToBlob(dataUri: string): Blob {
    var byteString;
    if (dataUri.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataUri.split(',')[1]);
    else
        byteString = unescape(dataUri.split(',')[1]);

    // separate out the mime component
    var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
  }

  submit() {
    this.httpClient.post('/api/submitcashin', {id: this.id, submitted:true}, {
      headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
    }).subscribe((result: any) => {
      if (result.result === 0) {
        this.step = 4;
      }
    });
  }

  back() {
    this.router.navigate(['/cash-in']);
  }

}
