<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<div class="d-flex justify-content-center align-items-center w-100 mt-2 mx-2">
    <div *ngIf="user">
        <h2>User</h2>
        <div>{{ user.id }}</div>
        <div>{{ user.fullName }}</div>
        <div>{{ user.agent?'Agent':'' }} {{ user.manager?'Manager':'' }} {{ user.admin?'Admin':'' }}</div>
        <div *ngIf="balances">
            <h3>Balances</h3>
            <table>
                <tr *ngFor="let balance of balances">
                    {{ balance.amount | currency:balance.currency }}
                </tr>
            </table>
        </div>
        <div *ngIf="user.cashOnHand">
            <h3>Cash On Hand</h3>
            <table>
                <tr *ngFor="let cash of cashOnHand">
                    {{ cash.amount | currency:cash.currency }}
                </tr>
            </table>
        </div>
        <div *ngIf="kycs" class="w-100">
            <h3>Kycs</h3>
            <table class="w-100">
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Approved</th>
                </tr>
                <tr *ngFor="let kyc of kycs" (click)="openKyc(kyc)" style="cursor: pointer">
                    <td>{{ kyc.id }}</td>
                    <td>{{ kyc.date | date }}</td>
                    <td>{{ kyc.approved }}</td>
                </tr>
            </table>
        </div>
        <div *ngIf="payments">
            <h3>Payments</h3>
            <table class="w-100">
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Complete</th>
                </tr>
                <tr *ngFor="let payment of payments" (click)="openPayment(payment.id)" style="cursor: pointer">
                    <td>{{ payment.id }}</td>
                    <td>{{ payment.date | date }}</td>
                    <td>{{ payment.complete }}</td>
                </tr>
            </table>
        </div>
        <div *ngIf="bookings">
            <h3>Bookings</h3>
            <table class="w-100">
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Payment</th>
                </tr>
                <tr *ngFor="let booking of bookings" (click)="openBooking(booking.id)" style="cursor: pointer">
                    <td>{{ booking.id }}</td>
                    <td>{{ booking.date | date }}</td>
                    <td>{{ booking.amount | currency:booking.currency }}</td>
                    <td>{{ booking.complete?'Completed':'' }}{{ booking.cancelled?'Cancelled':'' }}</td>
                    <td><a *ngIf="booking.payment" [routerLink]="'/payments/' + booking.payment">Yes</a></td>
                </tr>
            </table>
        </div>
        <div *ngIf="transactions">
            <h3>Transactions</h3>
            <table class="w-100">
                <tr>
                    <th>Id</th>
                    <th>Date</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Booking</th>
                    <th>Income</th>
                </tr>
                <tr *ngFor="let transaction of transactions">
                    <td>{{ transaction.id }}</td>
                    <td>{{ transaction.date | date }}</td>
                    <td>{{ transaction.type }}</td>
                    <td>{{ transaction.amount | currency:transaction.currency }}</td>
                    <td><span *ngIf="transaction.booking"><a [routerLink]="'/bookings/' + transaction.booking">{{ transaction.booking }}</a></span></td>
                    <td>{{ transaction.income | currency:transaction.currency }}</td>
                </tr>
            </table>
            <div *ngIf="transactionsBalance != undefined">Balance: {{ transactionsBalance | currency:'PHP' }}</div>
            <div *ngIf="transactionsIncome">Income: {{ transactionsIncome | currency:'PHP' }}</div>
        </div>
    </div>
    <div *ngIf="!user">
        Loading User
    </div>
</div>