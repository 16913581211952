<button class="back-button" (click)="back()">
    &leftarrow;
    Back
  </button>
  <div *ngIf="!sent">
    <div class="d-flex justify-content-center align-items-center w-100 mt-2 mx-2">
        <div *ngIf="cashIn" style="width:100%; max-width: 700px">
            <h2>Cash-In</h2>
            <div>{{ cashIn.id }}</div>
            <div *ngIf="user">
                <span *ngIf="cashIn.receiver == user.id" class="badge bg-danger">Receiver</span>
            </div>
            <div>Type: {{ cashIn.type }}</div>
            <div>Amount: {{ cashIn.amount | currency:cashIn.currency?cashIn.currency:'PHP' }}</div>
            <h3>Uploads</h3>
            <div *ngFor="let upload of cashIn.uploads"><a [href]="bucket + upload ">{{ upload }}</a></div>
            
            <div *ngIf="cashIn.receiverDetail">Receiver: <a [routerLink]="'/admin-users/' + cashIn.receiver">{{ cashIn.receiverDetail.fullName }}</a></div>
            <div *ngIf="cashIn.complete">Complete
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16">
                    <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
            </div>
            <div *ngIf="cashIn.settled">Settled
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
                    viewBox="0 0 16 16">
                    <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                </svg>
            </div>
            <div *ngIf="!cashIn.complete"><button (click)="applyCashIn()">Apply</button></div>
        </div>
    </div>
    <div *ngIf="sent">
        Sent
    </div>
      <div *ngIf="!cashIn">
          Loading Cash-In
      </div>
  </div>