<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<h2>Users</h2>

<div class="btn-group" role="group" aria-label="Radio Buttons with Bootstrap 5">
    <input type="radio" [formControl]="radio" value="EMAIL" class="btn-check" id="btnradio1" autocomplete="off">
    <label class="btn btn-outline-primary" for="btnradio1">E-mail</label>
    <input type="radio" [formControl]="radio" value="PHONE" class="btn-check" id="btnradio2" autocomplete="off">
    <label class="btn btn-outline-primary" for="btnradio2">Phone</label>
    <input type="radio" [formControl]="radio" value="ID" class="btn-check" id="btnradio3" autocomplete="off">
    <label class="btn btn-outline-primary" for="btnradio3">Id</label>
</div>

<div *ngIf="radio.value == 'EMAIL'">
    <form [formGroup]="emailForm">
        <div class="form-group">
            <label>Email</label>
            <div class="input-group mb-3">
                <input type="email" formControlName="email" class="form-control" placeholder="name@example.com">
                <button class="btn btn-outline-primary" (click)="getUserByEmail()">Search</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="radio.value == 'PHONE'">
    <form [formGroup]="phoneForm">
        <div class="form-group">
            <label>Phone</label>
            <div class="input-group mb-3">
                <input type="text" formControlName="phone" class="form-control" placeholder="09051234567">
                <button class="btn btn-outline-primary" (click)="getUserByPhone()">Search</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="radio.value == 'ID'">
    <form [formGroup]="idForm">
        <div class="form-group">
            <label>Id</label>
            <div class="input-group mb-3">
                <input type="text" formControlName="id" class="form-control" (change)="getUserById()">
                <button class="btn btn-outline-primary" (click)="getUserById()">Search</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="id">Name: {{ name }} <a [routerLink]="'/admin-users/' + this.id">{{ id }}</a></div>