import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { WsService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-kycs',
  templateUrl: './admin-kycs.component.html',
  styleUrls: ['./admin-kycs.component.scss']
})
export class AdminKycsComponent implements OnInit {

  model?: NgbDateStruct;
  
  kycs?: any;

  user?: any;

  constructor(private httpClient: HttpClient, private wsService: WsService, private router: Router, private route: ActivatedRoute, private userService: UserService, private authService: AuthService) { 
    let date = new Date();
    this.model = {year: date.getFullYear(), month: date.getMonth()+1, day: date.getDate()};
    this.kycs = this.route.snapshot.data['kycs'];

    this.userService.currentUserSubject.subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnInit(): void {

  }

  loadKycs() {
    if (this.model) {
      let year = this.model.year;
      let month = this.model.month>9?this.model.month:'0'+this.model.month;
      let day = this.model.day>9?this.model.day:'0'+this.model.day;
      this.httpClient.get('/api/kycs?ymd=' + year + '-' + month + '-' + day, {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((results) => {
        this.kycs = results;
      });
    }
  }

  openKyc(kyc: any) {
    this.router.navigate(['/kyc/' + kyc.userId + '!' + kyc.id]);
  }

  back() {
    this.router.navigate(['/']);
  }

}
