import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { SendScanComponent } from '../send-scan/send-scan.component';
import { WsService } from 'src/app/services/ws.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user';


@Component({
  selector: 'app-send',
  templateUrl: './send.component.html',
  styleUrls: ['./send.component.sass']
})
export class SendComponent implements OnInit {

  id?: string;
  user?: any;
  send?: any;

  fromTransactions = false;
  fromAdminTransactions = false;

  constructor(private route: ActivatedRoute, private router: Router, private httpClient: HttpClient, private modalService: NgbModal, private wsService: WsService, private userService: UserService, private authService: AuthService) { 
    this.id = route.snapshot.params['id'];
    const navigation = this.router.getCurrentNavigation();
    const state = navigation?.extras.state as { transactions?: boolean, adminTransactions?: boolean };
    if (state && state.transactions) {
      this.fromTransactions = state.transactions;
    }
    if (state && state.adminTransactions) {
      this.fromAdminTransactions = state.adminTransactions;
    }
    
    this.userService.currentUserSubject.subscribe((user: User) => {
      this.user = user;
    });
  }

  ngOnInit(): void {
    //this.googleMap!.setMyLocationEnabled(true);
    this.route.paramMap.subscribe((params: any) => { 
      this.send = null;
      this.id = params.get('id')!; 
      this.httpClient.get('/api/sends/' + this.id,  {
        headers: new HttpHeaders().append('Authorization','Bearer ' + this.authService.token)
      }).subscribe((result: any) => {
        this.send = result.send;
      })
    });
  }

  back() {
    if (this.fromTransactions) {
      this.router.navigate(['/transactions']);
    } else if (this.fromAdminTransactions) {
      this.router.navigate(['/admin-transactions']);
    } else {
      this.router.navigate(['/']);
    }
  }
}
