<button class="btn btn-primary" (click)="back()">&lt; Back</button>
<div class="d-flex justify-content-center align-items-center w-100 mt-2 mx-2">
    <div *ngIf="payment" style="width:100%; max-width: 700px">
        <h2>Payment</h2>
        <div>{{ payment.id }}</div>
        <div *ngIf="user">
          <span *ngIf="payment.sender == user.id" class="badge bg-success">Sender</span>
          <span *ngIf="payment.receiver == user.id" class="badge bg-danger">Receiver</span>
        </div>
        <div>Date: {{ payment.date | date:'long'}}</div>
        <div *ngIf="payment.bill">Bill: {{ payment.bill }}</div>
        <div *ngFor="let field of fields">{{ field.id }}: {{ field.value }}</div>
        <div>Type: {{ payment.type }}</div>
        <div>Amount: {{ payment.amount | currency:payment.currency?payment.currency:'PHP' }}</div>
        <div>Payments: {{ payment.intervals }}</div>
        <div>Frequency: {{ payment.intervalPeriod }} <span *ngIf="payment.intervalUnit == 'MONTH'">Month</span><span *ngIf="payment.intervalPeriod > 1">s</span></div>
        <div *ngIf="payment.senderDetail">Sender: <a [routerLink]="'/admin-users/' + payment.sender">{{ payment.senderDetail.fullName }}</a></div>
        <div *ngIf="payment.receiverDetail">Receiver: <a [routerLink]="'/admin-users/' + payment.receiver">{{ payment.receiverDetail.fullName }}</a></div>
        <div *ngIf="payment.booking">Booking: <a [href]="'/bookings/' + payment.booking">{{ payment.booking }}</a></div>
        <div *ngIf="payment.cashPickedUp">Cash Picked Up
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
              viewBox="0 0 16 16">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </div>
        <div *ngIf="payment.complete">Complete
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
              viewBox="0 0 16 16">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
        </div>
        <div *ngIf="payment.settled">Settled
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
              viewBox="0 0 16 16">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
        </div>
        <div *ngIf="payment.failed">Failed
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill"
              viewBox="0 0 16 16">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
        </div>
        <div *ngIf="payment.type == 'PAYMENT_TO_BILLER' && payment.settle == user.id" ><button (click)="pay()">Pay</button></div>
        <div *ngIf="payment.type == 'PAYMENT_TO_BILLER' && !payment.settled" ><button (click)="settle()">Settle</button></div>
        <table *ngIf="payment.bookings">
            <tr>
                <th>Booking Id</th>
                <th>Date</th>
                <th>Complete</th>
            </tr>
            <tr *ngFor="let booking of payment.bookings">
                <td>{{ booking.id }}</td>
                <td>{{ booking.date | date }}</td>
                <td>{{ booking.complete }}</td>
            </tr>
        </table>
        <div *ngIf="payment.wsTopUpStatusCode != undefined">WSTopUpStatusCode: {{ payment.wsTopUpStatusCode }}</div>
        <div *ngIf="payment.wsTopUpStatusMessage">WSTopUpStatusMessage: {{ payment.wsTopUpStatusMessage }}</div>
        <div *ngIf="payment.wsTopUpTraceNo">WSTopUpTraceNo: {{ payment.wsTopUpTraceNo }}</div>
        <div *ngIf="payment.ecPayStatus != undefined">ECPayStatus: {{ payment.ecPayStatus }}</div>
        <div *ngIf="payment.ecPayMessage != undefined">ECPayMessage: {{ payment.ecPayMessage }}</div>
        <div *ngIf="payment.ecPayTraceNumber != undefined">ECPayTraceNumber: {{ payment.ecPayTraceNumber }}</div>
        <div *ngIf="payment.ecPayPartnerReferenceNumber != undefined">ECPayPartnerReferenceNumber: {{ payment.ecPayPartnerReferenceNumber }}</div>
        <div *ngIf="payment.ecPayTransactionDateTime != undefined">ECPayTransactionDateTime: {{ payment.ecPayTransactionDateTime }}</div>

    </div>
    <div *ngIf="!payment">
        Loading Payment
    </div>
</div>
